import React, { useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';
import "./setting.css"
import MyAccount from './MyAccount';
import Team from './Team';
import Access from './Access';
import Billing from './Billing';

const Setting = () => {
  const [selectedTab, setSelectedTab] = useState('myAccount');
  const renderContent = () => {
    switch (selectedTab) {
      case 'myAccount':
        return <MyAccount />;
      case 'team':
        return <Team />;
      case 'access':
        return <Access />;
      case 'billing':
        return <Billing />;
      default:
        return null;
    }
  };
  return (
    <Box>
      <header>
        <Text
          fontSize={'28px'}
          fontWeight={'bold'}
          color={'#222222'}
          lineHeight={'32px'}
          marginTop={10}
          marginLeft={5}
        >
          Settings
        </Text>
        <Flex
          gap={10}
          marginTop={4}
          marginLeft={5}
          fontWeight={600}
          marginBottom={0.1}
        >
          <Text
            cursor="pointer"
            onClick={() => setSelectedTab('myAccount')}
            borderBottom={
              selectedTab === 'myAccount' ? '2.5px solid #EF6506' : 'none'
            }
            color={selectedTab === 'myAccount' ? '#EF6506' : 'none'}
          >
            My Account
          </Text>
          <Text
            cursor="pointer"
            onClick={() => setSelectedTab('team')}
            borderBottom={
              selectedTab === 'team' ? '2.5px solid #EF6506' : 'none'
            }
            color={selectedTab === 'team' ? '#EF6506' : 'none'}
          >
            Team
          </Text>
          <Text
            cursor="pointer"
            onClick={() => setSelectedTab('access')}
            borderBottom={
              selectedTab === 'access' ? '2.5px solid #EF6506' : 'none'
            }
            color={selectedTab === 'access' ? '#EF6506' : 'none'}
          >
            Access
          </Text>
          <Text
            cursor="pointer"
            onClick={() => setSelectedTab('billing')}
            borderBottom={
              selectedTab === 'billing' ? '2.5px solid #EF6506' : 'none'
            }
            color={selectedTab === 'billing' ? '#EF6506' : 'none'}
          >
            Billing
          </Text>
        </Flex>
        <HSeparator />
      </header>
      <main>{renderContent()}</main>
    </Box>
  );
};

export default Setting;
