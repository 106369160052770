import React, { useState } from 'react';
import { Box, Text, Flex, ChakraProvider, extendTheme } from '@chakra-ui/react';
import CustomSelect from 'components/peoplePage/CustomSelect';
import LineBarChart from './LineBarChart';
import Switch from 'components/Switch';
import Chip from 'components/Chip';

const theme = extendTheme({
  fonts: {
    body: 'Inter, sans-serif',
    heading: 'Inter, sans-serif',
  },
});

const data = [
  { xAxis: 'Feb', value1: 60, value2: 30, value3: 20 },
  { xAxis: 'Mar', value1: 80, value2: 90, value3: 60 },
  { xAxis: 'Apr', value1: 40, value2: 60, value3: 120 },
  { xAxis: 'May', value1: 90, value2: 50, value3: 70 },
  { xAxis: 'Jun', value1: 70, value2: 90, value3: 30 },
  { xAxis: 'Jul', value1: 60, value2: 70, value3: 90 },
  { xAxis: 'Aug', value1: 50, value2: 40, value3: 60 },
];

const chipsData = [
  { label: 'Author', variant: 'primary' },
  { label: 'Evidence', variant: 'secondary' },
  { label: 'Influence', variant: 'tertiary' },
];

const EvaluationSkillsBarGraph = ({
  data,
  period,
  onPeriodChange,
  periodOptions,
  barLables,
  selectedOption,
  setSelectedOption,
}) => {
  // const [period, setPeriod] = useState('6 Months');
  // const [selectedOption, setSelectedOption] = useState('Average');

  const handleOptionChange = (option) => {
    setSelectedOption((prevState) => ({
      ...prevState,
      evaluationSkills: {
        value: option === 'Average' ? 'avg' : 'median',
        label: option,
      },
    }));
  };

  return (
    <ChakraProvider theme={theme}>
      <Box
        bg="transparent"
        borderRadius="12px"
        overflow="hidden"
        border="1px solid #E7E7E7"
        paddingBottom={'3%'}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          p={4}
          pb={4}
          borderBottom={'1px solid #E7E7E7'}
        >
          <Box>
            <Text fontSize="18px" fontWeight="600" color="#101828">
              Evaluation skills
            </Text>
            <Text fontSize="14px" color="#475467" mt={1}>
              Description....
            </Text>
          </Box>
          <CustomSelect
            options={periodOptions}
            placeholder="6 months"
            width="150px"
            value={period}
            onChange={onPeriodChange}
          />
        </Flex>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={4}
          mt={'8px'}
          mb={'8px'}
        >
          <Flex alignItems="baseline">
            <Switch
              options={['Average', 'Median']}
              value={selectedOption?.evaluationSkills?.label}
              onChange={handleOptionChange}
            />
          </Flex>
          <Flex justifyContent="flex-start" px={4} mt={4} gap={'8px'}>
            {chipsData.map((chip, index) => (
              <Chip key={index} variant={chip.variant} label={chip.label} />
            ))}
          </Flex>
        </Flex>
        <LineBarChart
          data={data}
          isBarBackground={false}
          yAxisLabel={'s'}
          barLables={barLables}
        />
      </Box>
    </ChakraProvider>
  );
};

export default EvaluationSkillsBarGraph;
