import React from 'react';

// Chakra imports
import { Flex, useColorModeValue } from '@chakra-ui/react';

// Custom components
import { HSeparator } from 'components/separator/Separator';
import TwellaLogoIcon from 'assets/icons/twellaLogo';

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue('navy.700', 'white');

  return (
    <Flex align="center" direction="column">
      {/* <HorizonLogo h='26px' w='175px' my='32px' color={logoColor} /> */}

      <TwellaLogoIcon />

      {/* <HSeparator mb='20px' /> */}
    </Flex>
  );
}

export default SidebarBrand;
