import React from 'react';

function CancelIcon({ fill = '#E02B00', ...prop }) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M6.99996 1.16602C5.84624 1.16602 4.71842 1.50813 3.75914 2.14911C2.79985 2.79008 2.05218 3.70113 1.61066 4.76703C1.16915 5.83293 1.05363 7.00582 1.27871 8.13738C1.50379 9.26893 2.05937 10.3083 2.87517 11.1241C3.69098 11.9399 4.73038 12.4955 5.86194 12.7206C6.99349 12.9457 8.16638 12.8302 9.23228 12.3886C10.2982 11.9471 11.2092 11.1995 11.8502 10.2402C12.4912 9.28089 12.8333 8.15307 12.8333 6.99935C12.8333 6.2333 12.6824 5.47476 12.3893 4.76703C12.0961 4.0593 11.6664 3.41623 11.1248 2.87456C10.5831 2.33288 9.94001 1.9032 9.23228 1.61005C8.52455 1.3169 7.76601 1.16602 6.99996 1.16602ZM6.99996 11.666C5.76228 11.666 4.5753 11.1743 3.70013 10.2992C2.82496 9.42401 2.3333 8.23703 2.3333 6.99935C2.33201 5.96297 2.67922 4.95625 3.31913 4.14102L9.85829 10.6802C9.04306 11.3201 8.03634 11.6673 6.99996 11.666ZM10.6808 9.85768L4.14163 3.31852C4.95686 2.67861 5.96358 2.33139 6.99996 2.33268C8.23764 2.33268 9.42462 2.82435 10.2998 3.69952C11.175 4.57469 11.6666 5.76167 11.6666 6.99935C11.6679 8.03573 11.3207 9.04245 10.6808 9.85768Z"
        fill={fill}
      />
    </svg>
  );
}

export default CancelIcon;
