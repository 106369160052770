import React from 'react';

function RightOutlinedArrow({ fill = '#222222', ...prop }) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M10.4685 6.65558L4.30527 1.84171C4.28917 1.82903 4.26981 1.82115 4.24943 1.81897C4.22905 1.8168 4.20847 1.82042 4.19005 1.82941C4.17164 1.83841 4.15613 1.85241 4.14531 1.86982C4.1345 1.88724 4.12881 1.90734 4.12891 1.92784V2.98468C4.12891 3.05167 4.16035 3.11593 4.21231 3.15694L9.13417 7.00011L4.21231 10.8433C4.15899 10.8843 4.12891 10.9485 4.12891 11.0155V12.0724C4.12891 12.164 4.23418 12.2146 4.30527 12.1585L10.4685 7.34464C10.5209 7.30378 10.5633 7.25151 10.5924 7.19181C10.6216 7.13211 10.6367 7.06654 10.6367 7.00011C10.6367 6.93367 10.6216 6.86811 10.5924 6.80841C10.5633 6.74871 10.5209 6.69644 10.4685 6.65558Z"
        fill={fill}
      />
    </svg>
  );
}

export default RightOutlinedArrow;
