import React from 'react'

const Billing = () => {
  return (
    <div>
      Billing
    </div>
  )
}

export default Billing
