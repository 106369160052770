// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextInformation {
    padding-bottom: 32px;
    border-bottom: 1px solid #E7E7E7;
}

.TextInput {
    width: 100%;
    padding: 5px 6px;
    outline: none;
    border: none;
    border: 1px solid #E7E7E7;
    border-radius: 8px;
    background: transparent;
    color: #222222;
}

.TextInformation>div:not(:last-child) {
    margin-bottom: 16px;
}

.Notifications>div:not(:last-child) {
    margin-bottom: 8px;
}

#lastName {
    margin-left: 16px;
}`, "",{"version":3,"sources":["webpack://./src/views/admin/settings/setting.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,gCAAgC;AACpC;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,uBAAuB;IACvB,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".TextInformation {\n    padding-bottom: 32px;\n    border-bottom: 1px solid #E7E7E7;\n}\n\n.TextInput {\n    width: 100%;\n    padding: 5px 6px;\n    outline: none;\n    border: none;\n    border: 1px solid #E7E7E7;\n    border-radius: 8px;\n    background: transparent;\n    color: #222222;\n}\n\n.TextInformation>div:not(:last-child) {\n    margin-bottom: 16px;\n}\n\n.Notifications>div:not(:last-child) {\n    margin-bottom: 8px;\n}\n\n#lastName {\n    margin-left: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
