import React from 'react';

function ArrowUpIcon({ fill = '#EF6506', ...prop }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_152_2186)">
        <path
          d="M4 12L5.41 13.41L11 7.83V20H13V7.83L18.58 13.42L20 12L12 4L4 12Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_152_2186">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 -1 24 24)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ArrowUpIcon;
