import React, { useEffect, useState } from 'react';
import { Box, Text, Flex, ChakraProvider, extendTheme } from '@chakra-ui/react';
import CustomSelect from 'components/peoplePage/CustomSelect';
import LineBarChart from './LineBarChart';
import Switch from 'components/Switch';
import Chip from 'components/Chip';

const theme = extendTheme({
  fonts: {
    body: 'Inter, sans-serif',
    heading: 'Inter, sans-serif',
  },
});

const data = [
  { xAxis: 'Feb', value1: 60, value2: 30 },
  { xAxis: 'Mar', value1: 80, value2: 90 },
  { xAxis: 'Apr', value1: 40, value2: 60 },
  { xAxis: 'May', value1: 90, value2: 50 },
  { xAxis: 'Jun', value1: 70, value2: 90 },
  { xAxis: 'Jul', value1: 60, value2: 70 },
  { xAxis: 'Aug', value1: 50, value2: 40 },
];

const TimeSpendOnCloserLookBarGraph = ({
  data,
  period,
  onPeriodChange,
  periodOptions,
  barLables,
  selectedOption,
  setSelectedOption,
  userInfo,
}) => {
  // const [period, setPeriod] = useState('6 Months');
  // const [selectedOption, setSelectedOption] = useState('Average');

  const handleOptionChange = (option) => {
    setSelectedOption((prevState) => ({
      ...prevState,
      timeSpendOnCloserLook: {
        value: option === 'Average' ? 'avg' : 'median',
        label: option,
      },
    }));
  };

  return (
    <ChakraProvider theme={theme}>
      <Box
        bg="transparent"
        borderRadius="12px"
        overflow="hidden"
        border="1px solid #E7E7E7"
        paddingBottom={'3%'}
        height={'100%'}
        textTransform={'capitalize'}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          p={4}
          pb={4}
          borderBottom={'1px solid #E7E7E7'}
        >
          <Box>
            <Text fontSize="18px" fontWeight="600" color="#101828">
              Time spent on Closer Look
            </Text>
            <Text fontSize="14px" color="#475467" mt={1}>
              Description....
            </Text>
          </Box>
          <CustomSelect
            options={periodOptions}
            placeholder="6 months"
            width="150px"
            value={period}
            onChange={onPeriodChange}
          />
        </Flex>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={4}
          mt={'8px'}
          mb={'8px'}
        >
          <Flex alignItems="baseline">
            <Switch
              options={['Average', 'Median']}
              value={selectedOption?.timeSpendOnCloserLook?.label}
              onChange={handleOptionChange}
            />
          </Flex>
          <Flex justifyContent="flex-start" px={4} mt={4} gap={'8px'}>
            {userInfo?.name && (
              <Chip
                variant={'primary'}
                label={`${userInfo?.name
                  ?.charAt(0)
                  ?.toUpperCase()}${userInfo?.name?.slice(1)?.toLowerCase()}`}
                hasAvatar
              />
            )}

            {userInfo?.institution?.name && (
              <Chip
                variant={'tertiary'}
                label={`${userInfo?.institution?.name
                  ?.charAt(0)
                  ?.toUpperCase()}${userInfo?.institution?.name
                  ?.slice(1)
                  ?.toLowerCase()}`}
                hasAvatar
              />
            )}

            {userInfo?.label && (
              <Flex justify={'center'} align={'center'}>
                <Box
                  width={'8px'}
                  height={'8px'}
                  borderRadius={'50%'}
                  bg={'#EF6506'}
                  marginRight={'4px'}
                ></Box>
                <Box
                  as="span"
                  fontWeight={'700'}
                  fontSize={'12px'}
                  lineHeight={'14px'}
                >
                  {userInfo?.label}
                </Box>
              </Flex>
            )}
          </Flex>
        </Flex>
        <LineBarChart
          data={data}
          isBarBackground={false}
          yAxisLabel={'m'}
          barLables={barLables}
        />
      </Box>
    </ChakraProvider>
  );
};

export default TimeSpendOnCloserLookBarGraph;
