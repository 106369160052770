import React from 'react';

const CopyPasteIcon = ({ width = 16, height = 16, fill = 'none', ...prop }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill={fill}>
    <g clip-path="url(#clip0_296_10581)">
      <path
        d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
        stroke="#FFFDFB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33325 10.0007H2.66659C2.31296 10.0007 1.97382 9.86018 1.72378 9.61013C1.47373 9.36008 1.33325 9.02094 1.33325 8.66732V2.66732C1.33325 2.3137 1.47373 1.97456 1.72378 1.72451C1.97382 1.47446 2.31296 1.33398 2.66659 1.33398H8.66659C9.02021 1.33398 9.35935 1.47446 9.60939 1.72451C9.85944 1.97456 9.99992 2.3137 9.99992 2.66732V3.33398"
        stroke="#FFFDFB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_296_10581">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CopyPasteIcon;

