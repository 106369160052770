// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.singlePersonalData {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 2%;
}

.singlePersonalData>div:nth-of-type(1),
.singlePersonalData>div:nth-of-type(2) {
  flex-basis: 48%;
}

.EngagementCategories>div:not(:last-child) {
  margin-bottom: 16px;
}

.personalDataCard {
  padding: 16px 16px 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.barChat.css-odmu8y {
  background-color: transparent !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/PersonalInfo.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,uBAAuB;EACvB,eAAe;EACf,OAAO;AACT;;AAEA;;EAEE,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;EAC3B,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":[".singlePersonalData {\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-wrap: wrap;\n  gap: 2%;\n}\n\n.singlePersonalData>div:nth-of-type(1),\n.singlePersonalData>div:nth-of-type(2) {\n  flex-basis: 48%;\n}\n\n.EngagementCategories>div:not(:last-child) {\n  margin-bottom: 16px;\n}\n\n.personalDataCard {\n  padding: 16px 16px 8px 16px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.barChat.css-odmu8y {\n  background-color: transparent !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
