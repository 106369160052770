import React from 'react';

function PhoneIcon({ fill = '#222222', ...prop }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_323_1620)">
        <path
          d="M14.6669 11.2807V13.2807C14.6677 13.4664 14.6297 13.6502 14.5553 13.8203C14.4809 13.9904 14.3718 14.1431 14.235 14.2686C14.0982 14.3941 13.9367 14.4897 13.7608 14.5492C13.5849 14.6087 13.3985 14.6308 13.2136 14.614C11.1622 14.3911 9.19161 13.6901 7.46028 12.5674C5.8495 11.5438 4.48384 10.1782 3.46028 8.56738C2.3336 6.82819 1.63244 4.84805 1.41361 2.78738C1.39695 2.60303 1.41886 2.41722 1.47795 2.2418C1.53703 2.06637 1.63199 1.90518 1.75679 1.76846C1.88159 1.63175 2.03348 1.52252 2.20281 1.44773C2.37213 1.37294 2.55517 1.33422 2.74028 1.33405H4.74028C5.06382 1.33086 5.37748 1.44543 5.62279 1.6564C5.8681 1.86737 6.02833 2.16035 6.07361 2.48072C6.15803 3.12076 6.31458 3.7492 6.54028 4.35405C6.62998 4.59266 6.64939 4.85199 6.59622 5.1013C6.54305 5.35061 6.41952 5.57946 6.24028 5.76072L5.39361 6.60738C6.34265 8.27641 7.72458 9.65835 9.39361 10.6074L10.2403 9.76072C10.4215 9.58147 10.6504 9.45795 10.8997 9.40478C11.149 9.35161 11.4083 9.37102 11.6469 9.46072C12.2518 9.68642 12.8802 9.84297 13.5203 9.92738C13.8441 9.97307 14.1399 10.1362 14.3513 10.3857C14.5627 10.6352 14.6751 10.9538 14.6669 11.2807Z"
          stroke="#222222"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_323_1620">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PhoneIcon;
