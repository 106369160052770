import React from 'react';

function VerifiedIcon({ fill = '#222222', ...prop }) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M8.58663 5.12685L6.08413 7.63518L5.12163 6.67268C5.06933 6.61162 5.00498 6.56202 4.93261 6.52701C4.86024 6.492 4.78141 6.47232 4.70108 6.46922C4.62074 6.46611 4.54063 6.47965 4.46577 6.50898C4.39092 6.5383 4.32293 6.58279 4.26608 6.63964C4.20923 6.69648 4.16475 6.76447 4.13542 6.83933C4.1061 6.91418 4.09256 6.99429 4.09566 7.07463C4.09877 7.15497 4.11844 7.23379 4.15346 7.30616C4.18847 7.37854 4.23806 7.44289 4.29913 7.49518L5.66996 8.87185C5.72447 8.92591 5.78911 8.96869 5.86018 8.99771C5.93125 9.02674 6.00736 9.04146 6.08413 9.04102C6.23716 9.04037 6.38381 8.97962 6.49246 8.87185L9.40913 5.95518C9.4638 5.90095 9.5072 5.83644 9.53682 5.76535C9.56643 5.69427 9.58168 5.61802 9.58168 5.54102C9.58168 5.46401 9.56643 5.38776 9.53682 5.31668C9.5072 5.24559 9.4638 5.18108 9.40913 5.12685C9.29983 5.0182 9.15199 4.95722 8.99788 4.95722C8.84377 4.95722 8.69592 5.0182 8.58663 5.12685ZM6.99996 1.16602C5.84624 1.16602 4.71842 1.50813 3.75914 2.14911C2.79985 2.79008 2.05218 3.70113 1.61066 4.76703C1.16915 5.83293 1.05363 7.00582 1.27871 8.13738C1.50379 9.26893 2.05937 10.3083 2.87517 11.1241C3.69098 11.9399 4.73038 12.4955 5.86194 12.7206C6.99349 12.9457 8.16638 12.8302 9.23228 12.3886C10.2982 11.9471 11.2092 11.1995 11.8502 10.2402C12.4912 9.28089 12.8333 8.15307 12.8333 6.99935C12.8333 6.2333 12.6824 5.47476 12.3893 4.76703C12.0961 4.0593 11.6664 3.41623 11.1248 2.87456C10.5831 2.33288 9.94001 1.9032 9.23228 1.61005C8.52455 1.3169 7.76601 1.16602 6.99996 1.16602ZM6.99996 11.666C6.07698 11.666 5.17473 11.3923 4.4073 10.8795C3.63987 10.3668 3.04173 9.63793 2.68852 8.7852C2.33532 7.93248 2.2429 6.99417 2.42296 6.08893C2.60303 5.18368 3.04749 4.35216 3.70013 3.69952C4.35278 3.04687 5.1843 2.60242 6.08954 2.42235C6.99479 2.24229 7.9331 2.3347 8.78582 2.68791C9.63854 3.04112 10.3674 3.63926 10.8802 4.40669C11.3929 5.17412 11.6666 6.07637 11.6666 6.99935C11.6666 8.23703 11.175 9.42401 10.2998 10.2992C9.42462 11.1743 8.23764 11.666 6.99996 11.666Z"
        fill={fill}
      />
    </svg>
  );
}

export default VerifiedIcon;
