import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  CircularProgress,
  CircularProgressLabel,
} from '@chakra-ui/react';

const PieChart = () => {
  const data = [
    { category: 'Politics', value: 40 },
    { category: 'Health', value: 21 },
    { category: 'Environment', value: 14 },
    { category: 'Technology', value: 10 },
    { category: 'Media', value: 8 },
    { category: 'Finances', value: 7 },
  ];
  return (
    <Box maxW="md" mx="auto" mt={10}>
      <Heading size="md" mb={8}>
        Categories
      </Heading>
      <Flex direction="column" align="start" spacing={8}>
        {data.map((item, index) => (
          <Flex key={index} align="center" justify="space-between" w="full">
            <Text fontSize="md" fontWeight="medium">
              {item.category}
            </Text>
            <CircularProgress value={item.value} size="40px" color="orange.400">
              <CircularProgressLabel fontSize="md" color="orange.400">
                {item.value}%
              </CircularProgressLabel>
            </CircularProgress>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};

export default PieChart;
