import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Text,
  VStack,
  HStack,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import PhoneInputComponent from 'components/phoneInput/countryPicker';
import axios from 'axios';
import { useAuth } from '@clerk/clerk-react';

// const API_URL = 'https://api.qa.twella.app/api/v1';
const API_URL =
  process.env.REACT_APP_API_URL || 'https://api.qa.twella.app/api/v1';

const ShareInviteModal = ({ onClose, isOpen, onSendInvite }) => {
  const [email, setEmail] = useState('');
  const [emails, setEmails] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [ token , setToken] = useState('')
  const toast = useToast();
  const { getToken } = useAuth();

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email.length) {
      const emails = email
        .toLowerCase()
        .split(',')
        .map((email) => email.trim().toLowerCase())
        .filter(Boolean);

      let isEmailValid = true;
      for (const emailId of emails) {
        isEmailValid = emailRegex.test(emailId);
        if (isEmailValid === false) {
          break;
        }
        setEmails(emails);
      }
      // const isPhoneValid = phoneNumber.length >= 10;
      setIsValid(isEmailValid);
    }
  }, [email]);

  // useEffect(() => {
  //   const token = localStorage.getItem('token');
  //   setToken(token);
  // }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const token = localStorage.getItem('token'); // Ensure you are getting the correct token
      const currentInstitution = JSON.parse(
        localStorage.getItem('institution'),
      );
      const headers = {
        Authorization: `Bearer ${token}`, // Pass the correct token here
      };

      let body = {};
      if (email) {
        body.emails = emails;
      } else if (phoneNumber) {
        body.phoneNumber = phoneNumber;
      }
      body.institution = currentInstitution?._id;

      const response = await axios.post(
        `${API_URL}/dashboard/users/inviteAppUser?invitationType=${
          body?.emails ? 'email' : 'phone'
        }`,
        body,
        { headers: headers },
      );

      const { message } = response.data;

      toast({
        title: 'Invite Sent',
        description: message || 'Invitation has been sent successfully.',
        status: 'success',
        duration: 10000,
        isClosable: true,
      });

      onSendInvite({ email, phoneNumber });
      setEmail('');
      setPhoneNumber('');
      onClose();
    } catch (error) {
      console.log('invite error', error);

      toast({
        title: 'Error',
        description:
          error.response?.data?.message ||
          'An error occurred while sending the invite.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
      <ModalOverlay bg="#53535399" />
      <ModalContent bg={bgColor} borderColor={borderColor} borderWidth="1px">
        <ModalHeader>Invite People</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="sm" color="gray.500" mt={'-6'} mb={4}>
            Invite new users via email or phone number
          </Text>
          <form onSubmit={handleSubmit}>
            <VStack spacing={0}>
              <FormControl>
                <FormLabel
                  htmlFor="email"
                  fontSize="12px"
                  fontWeight="bold"
                  color={'#46483C'}
                >
                  Email
                </FormLabel>
                <Textarea
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="user@mail.com"
                  borderColor={'#AAAAAA'}
                />
              </FormControl>
              {/* <Text pt={'4px'} color="gray.500">
                or
              </Text>
              <FormControl>
                <FormLabel
                  htmlFor="phone"
                  fontSize="12px"
                  fontWeight="bold"
                  color={'#46483C'}
                >
                  Phone number
                </FormLabel>
                <PhoneInputComponent
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                />
              </FormControl> */}
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={3}>
            <Button
              variant="outline"
              onClick={onClose}
              borderRadius={'8px'}
              borderColor={'#AAAAAA'}
              height={'36px'}
              fontSize={'14px'}
              fontWeight={'700'}
            >
              Cancel
            </Button>
            <Button
              bg={'#EF6506'}
              color={'white'}
              onClick={handleSubmit}
              borderRadius={'8px'}
              borderColor={'#AAAAAA'}
              height={'36px'}
              fontSize={'14px'}
              fontWeight={'700'}
              _hover={'none'}
              isLoading={isLoading}
              isDisabled={!isValid}
            >
              Send invite
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ShareInviteModal;
