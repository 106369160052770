import React from 'react';
import {
  Box,
  Text,
  Flex,
  ChakraProvider,
  extendTheme,
} from '@chakra-ui/react';
import CustomSelect from 'components/peoplePage/CustomSelect';
import LegendDot from 'assets/icons/LegendDot';
import LineBarChart from './LineBarChart';

const theme = extendTheme({
  fonts: {
    body: 'Inter, sans-serif',
    heading: 'Inter, sans-serif',
  },
});

const PostNumberBarGraph = ({
  data,
  period,
  onPeriodChange,
  graphData,
  barLables,
  periodOptions,
  chipsPercentage,
}) => {
  return (
    <ChakraProvider theme={theme}>
      <Box
        bg="transparent"
        borderRadius="12px"
        overflow="hidden"
        border="1px solid #E7E7E7"
        paddingBottom={'3%'}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          p={4}
          pb={4}
          borderBottom={'1px solid #E7E7E7'}
        >
          <Box>
            <Text fontSize="18px" fontWeight="600" color="#101828">
              Posts number
            </Text>
            <Text fontSize="14px" color="#475467" mt={1}>
              Description....
            </Text>
          </Box>
          <CustomSelect
            options={periodOptions}
            width="150px"
            value={period}
            onChange={onPeriodChange}
          />
        </Flex>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={4}
          mt={'8px'}
          mb={8}
        >
          <Flex alignItems="baseline">
            <Text fontSize="20px" fontWeight="600" color="#101828">
              {graphData?.totalPosts}
            </Text>
            <Text fontSize="14px" color="#475467" ml={2}>
              posts
            </Text>
          </Flex>
          <Flex alignItems={'center'}>
            {chipsPercentage?.map((platformData, index) => (
              <Flex key={index} alignItems="center" mr={'10px'}>
                <LegendDot fill={getLegendDotColor(index)} />
                <Text
                  fontSize="12px"
                  fontWeight={'700'}
                  color="#475467"
                  ml={'5px'}
                >
                  {platformData?.name} ({platformData?.percentage?.toFixed(1)}
                  %)
                </Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
        <LineBarChart
          data={data}
          isBarBackground={true}
          barLables={barLables}
        />
      </Box>
    </ChakraProvider>
  );
};

// Helper function to determine legend dot color
const getLegendDotColor = (index) => {
  const colors = ['#FFCC95', '#676666', '#F87171']; // Define your colors here
  return colors[index % colors.length]; // Cycle through colors based on index
};

export default PostNumberBarGraph;
