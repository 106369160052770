import React, { useEffect, useState } from 'react';
import {
  Flex,
  Box,
  Table,
  Checkbox,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  HStack,
  Button,
  Avatar,
  useToast,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import VerifiedIcon from 'assets/icons/verifiedIcon';
import CancelIcon from 'assets/icons/deletedIcon';
import RightOutlinedArrow from 'assets/icons/RightOutlinedArrow';
import LeftOutlineArrow from 'assets/icons/LeftOutlineArrow';

const columnHelper = createColumnHelper();

export default function CheckTable({
  visibleColumns,
  tableData,
  setShowProfile,
  setSingleUserData,
  totalUsersLength,
  currentPage,
  setCurrentPage,
  setSelectedRows,
  selectedRows,
}) {
  const [sorting, setSorting] = useState([]);
  const itemsPerPage = 10;
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const [data, setData] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (Array.isArray(tableData)) {
      if (
        tableData.length === 0 ||
        tableData[0]?.message !== 'No records found'
      ) {
        setData(tableData);
      }
    }
  }, [tableData]);

  const toggleAllRows = (checked) => {
    if (checked) {
      const allSelectedUsers = data
        .filter((item) => item.email)
        .map((item) => ({
          email: item.email,
          clerkUserId: item.clerkUserId,
        }));
      setSelectedRows(allSelectedUsers);
    } else {
      setSelectedRows([]);
    }
  };

  const columns = [
    visibleColumns?.name &&
      columnHelper.accessor('select', {
        header: ({ table }) => (
          <Box onClick={(e) => e.stopPropagation()}>
            <Checkbox
              colorScheme="orange"
              borderColor="#AAAAAA"
              isChecked={isAllSelected}
              onChange={(e) => {
                setIsAllSelected(!isAllSelected);
                toggleAllRows(e.target.checked);
              }}
            />
          </Box>
        ),
        cell: ({ row }) => {
          return (
            <Checkbox
              colorScheme="orange"
              borderColor={'#AAAAAA'}
              isChecked={selectedRows.some(
                (item) => item.email === row.original.email,
              )}
              onChange={(e) => {
                const isChecked = e.target.checked;
                const email = row.original.email;

                if (isChecked && !email) {
                  toast({
                    title: 'Error',
                    description: "User doesn't have email id",
                    variant: 'solid',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                  });
                } else {
                  const newData = data.map((item) =>
                    item.id === row.original.id
                      ? { ...item, isSelected: isChecked }
                      : item,
                  );
                  setData(newData);
                  setSelectedRows((prev) => {
                    if (isChecked) {
                      return [
                        ...prev,
                        {
                          email: email,
                          clerkUserId: row.original.clerkUserId,
                        },
                      ];
                    } else {
                      return prev.filter((item) => item.email !== email);
                    }
                  });
                }
              }}
              onClick={(e) => e.stopPropagation()}
            />
          );
        },
        size: 50,
      }),
    visibleColumns?.name &&
      columnHelper.accessor('name', {
        header: () => (
          <Flex align="center">
            <Text
              fontSize="14px"
              fontWeight="700"
              paddingLeft={'16px'}
              style={{ borderLeft: '.5px solid #E7E7E7' }}
              minWidth={'150px'}
            >
              NAME
            </Text>
          </Flex>
        ),
        cell: (info) => (
          <Flex align="center">
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Flex alignItems={'center'} minWidth={'150px'}>
                {info.getValue() && (
                  <Avatar
                    src="https://bit.ly/broken-link"
                    height={'32px'}
                    width={'32px'}
                    boxSize="32px"
                    borderRadius="full"
                    mr="3"
                  />
                )}
                <Text fontSize="14px" fontWeight="500" color={'#222222'}>
                  {info.getValue()}
                </Text>
              </Flex>
            </Box>
          </Flex>
        ),
      }),
    visibleColumns?.email &&
      columnHelper.accessor('email', {
        header: () => (
          <Box
            paddingLeft={'16px'}
            style={{
              borderLeft: '.5px solid #E7E7E7',
            }}
            minWidth={'150px'}
          >
            <Text fontSize="14px" fontWeight="700">
              EMAIL
            </Text>
          </Box>
        ),
        cell: (info) => (
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            paddingLeft={'16px'}
            minWidth={'150px'}
          >
            <Text fontSize="14px" fontWeight={'400'} color={'#222222'}>
              {info.getValue()}
            </Text>
          </Box>
        ),
      }),
    visibleColumns?.id &&
      columnHelper.accessor('id', {
        header: () => (
          <Text
            fontSize="14px"
            fontWeight="700"
            paddingLeft={'16px'}
            style={{
              borderLeft: '.5px solid #E7E7E7',
            }}
            minWidth={'118px'}
          >
            ID
          </Text>
        ),
        cell: (info) => (
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            paddingLeft={'16px'}
            minWidth={'118px'}
          >
            <Text fontSize="14px" fontWeight={'400'} color={'#222222'}>
              {info.getValue()}
            </Text>
          </Box>
        ),
      }),
    visibleColumns?.location &&
      columnHelper.accessor('location', {
        header: () => (
          <Text
            fontSize="14px"
            fontWeight="700"
            paddingLeft={'16px'}
            style={{
              borderLeft: '.5px solid #E7E7E7',
            }}
            minWidth={'130px'}
          >
            LOCATION
          </Text>
        ),
        cell: (info) => (
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            paddingLeft={'16px'}
            minWidth={'130px'}
          >
            <Text fontSize="14px" fontWeight={'400'} color={'#222222'}>
              {info.getValue()}
            </Text>
          </Box>
        ),
      }),
    visibleColumns?.lastSessions &&
      columnHelper.accessor('lastSession', {
        header: () => (
          <Text
            fontSize="14px"
            fontWeight="700"
            paddingLeft={'16px'}
            style={{
              borderLeft: '.5px solid #E7E7E7',
            }}
            minWidth={'138px'}
          >
            LAST SESSION
          </Text>
        ),
        cell: (info) => (
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            paddingLeft={'16px'}
            minWidth={'138px'}
          >
            <Text fontSize="14px" fontWeight={'400'} color={'#222222'}>
              {info.getValue()}
            </Text>
          </Box>
        ),
      }),
    visibleColumns?.totalSessions &&
      columnHelper.accessor('totalSessions', {
        header: () => (
          <Text
            fontSize="14px"
            fontWeight="700"
            paddingLeft={'16px'}
            style={{
              borderLeft: '.5px solid #E7E7E7',
            }}
            minWidth={'138px'}
          >
            TOTAL SESSIONS #
          </Text>
        ),
        cell: (info) => (
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            paddingLeft={'22px'}
            minWidth={'138px'}
          >
            <Text fontSize="14px" fontWeight={'400'} color={'#222222'}>
              {info.getValue()}
            </Text>
          </Box>
        ),
      }),
    visibleColumns?.status &&
      columnHelper.accessor('status', {
        header: () => (
          <Text
            fontSize="14px"
            fontWeight="700"
            paddingLeft={'16px'}
            style={{
              borderLeft: '.5px solid #E7E7E7',
            }}
            minWidth={'120px'}
          >
            STATUS
          </Text>
        ),
        cell: (info) => (
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            paddingLeft={'16px'}
            minWidth={'120px'}
          >
            <Flex align="center" display={'flex'} gap={'4px'}>
              {info.getValue() === 'Verified' ? (
                <>{info.getValue() && <VerifiedIcon />}</>
              ) : (
                <>{info.getValue() && <CancelIcon />}</>
              )}
              <Text
                fontSize="14px"
                fontWeight={'400'}
                color={info.getValue() === 'Verified' ? '#222222' : '#E02B00'}
              >
                {info.getValue()}
              </Text>
            </Flex>
          </Box>
        ),
      }),
    visibleColumns?.subscriber &&
      columnHelper.accessor('subscriber', {
        header: () => (
          <Text
            fontSize="14px"
            fontWeight="700"
            paddingLeft={'16px'}
            style={{
              borderLeft: '.5px solid #E7E7E7',
            }}
            minWidth={'128px'}
          >
            SUBSCRIBER
          </Text>
        ),
        cell: (info) => (
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            paddingLeft={'16px'}
            minWidth={'128px'}
          >
            {info.getValue() && (
              <Text
                fontSize="14px"
                fontWeight="500"
                color={
                  info.getValue() === 'Active'
                    ? '#25A259'
                    : info.getValue() === 'Trial'
                    ? '#FA8638'
                    : '#676666'
                }
                bg={
                  info.getValue() === 'Active'
                    ? '#25A25914'
                    : info.getValue() === 'Trial'
                    ? '#FFCC951F'
                    : '#67666614'
                }
                padding={'1px 12px 1px 12px'}
                borderRadius={'12px'}
                border={
                  info.getValue() === 'Active'
                    ? '1px solid #25A259'
                    : info.getValue() === 'Trial'
                    ? '1px solid #FA8638'
                    : '1px solid #676666'
                }
              >
                {info.getValue()}
              </Text>
            )}
          </Box>
        ),
      }),
    visibleColumns?.subscription &&
      columnHelper.accessor('subscription', {
        header: () => (
          <Text
            fontSize="14px"
            fontWeight="700"
            paddingLeft={'16px'}
            style={{
              borderLeft: '.5px solid #E7E7E7',
            }}
            minWidth={'158px'}
          >
            SUBSCRIPTION
          </Text>
        ),
        cell: (info) => (
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            paddingLeft={'16px'}
            minWidth={'158px'}
          >
            <Text fontSize="14px" fontWeight={'400'} color={'#222222'}>
              {info.getValue()}
            </Text>
          </Box>
        ),
      }),
    visibleColumns?.age &&
      columnHelper.accessor('age', {
        header: () => (
          <Text
            fontSize="14px"
            fontWeight="700"
            paddingLeft={'16px'}
            style={{
              borderLeft: '.5px solid #E7E7E7',
            }}
            minWidth={'60px'}
          >
            AGE
          </Text>
        ),
        cell: (info) => (
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            paddingLeft={'16px'}
          >
            <Text
              fontSize="14px"
              fontWeight={'400'}
              color={'#222222'}
              minWidth={'60px'}
            >
              {info.getValue()}
            </Text>
          </Box>
        ),
      }),
    visibleColumns?.gender &&
      columnHelper.accessor('gender', {
        header: () => (
          <Text
            fontSize="14px"
            fontWeight="700"
            paddingLeft={'16px'}
            style={{
              borderLeft: '.5px solid #E7E7E7',
            }}
            minWidth={'96px'}
          >
            GENDER
          </Text>
        ),
        cell: (info) => (
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            paddingLeft={'16px'}
            minWidth={'96px'}
          >
            <Text fontSize="14px" fontWeight={'400'} color={'#222222'}>
              {info.getValue()}
            </Text>
          </Box>
        ),
      }),
    visibleColumns?.race &&
      columnHelper.accessor('race', {
        header: () => (
          <Text
            fontSize="14px"
            fontWeight="700"
            paddingLeft={'16px'}
            style={{
              borderLeft: '.5px solid #E7E7E7',
            }}
            minWidth={'220px'}
          >
            RACE
          </Text>
        ),
        cell: (info) => (
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            paddingLeft={'16px'}
            minWidth={'220px'}
          >
            <Text fontSize="14px" fontWeight={'400'} color={'#222222'}>
              {info.getValue()}
            </Text>
          </Box>
        ),
      }),
    visibleColumns?.politicalPreferences &&
      columnHelper.accessor('politicalPreferences', {
        header: () => (
          <Text
            fontSize="14px"
            fontWeight="700"
            paddingLeft={'16px'}
            style={{
              borderLeft: '.5px solid #E7E7E7',
            }}
            minWidth={'220px'}
          >
            POLITICAL PREFERENCES
          </Text>
        ),
        cell: (info) => (
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            paddingLeft={'16px'}
            minWidth={'220px'}
          >
            <Text fontSize="14px" fontWeight={'400'} color={'#222222'}>
              {info.getValue()}
            </Text>
          </Box>
        ),
      }),
  ].filter(Boolean);

  useEffect(() => {
    if (tableData && tableData.length < 0) {
      console.log('empty');
    } else {
      console.log(
        'table data else 0 No data available----->',
        tableData[0].message,
      );
    }
  }, [tableData]);

  const table = useReactTable({
    data,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  // if (!data || data.length === 0) {
  //   return <Box>No data available</Box>;
  // }

  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return table.getRowModel().rows.slice(startIndex, endIndex);
  };

  const totalPages = Math.ceil(totalUsersLength / itemsPerPage);

  const getCurrentPageSet = () => {
    return Math.floor((currentPage - 1) / 5) * 5 + 1;
  };

  return (
    <>
      <Card overflowX="auto" backgroundColor={'#FFFDFB'} margin={0} padding={0}>
        <Table variant="simple" color="gray.500">
          <Thead>
            <Tr>
              {table.getHeaderGroups()[0].headers.map((header) => (
                <Th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  borderTop="1px solid"
                  borderBottom="1px solid"
                  borderColor={borderColor}
                  py="3"
                  px="4"
                  whiteSpace="nowrap"
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </Th>
              ))}
            </Tr>
          </Thead>
          {data.length > 0 ? (
            <Tbody>
              {getCurrentPageData().map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      borderBottom="1px solid"
                      borderColor={borderColor}
                      py="3"
                      px="4"
                      whiteSpace="nowrap"
                      style={{ cursor: 'pointer' }}
                    >
                      {cell.column.id === 'select' ? (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )
                      ) : (
                        <Box
                          onClick={(e) => {
                            e.stopPropagation();
                            setSingleUserData(row.original);
                            setShowProfile(true);
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Box>
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          ) : (
            <Box width={'250px'} justifyContent={'center'} alignItems={'center'} >
              <Text margin={'16px 0px 0px 26px'}>No data available </Text>
            </Box>
          )}
        </Table>
      </Card>
      <Flex justify="space-between" align="center" px="25px" mt="4">
        <Text fontSize="14px" color="gray.600">
          Rows: {getCurrentPageData().length} of{' '}
          {table.getRowModel().rows.length}
        </Text>
        <HStack spacing="2">
          <Button
            size="sm"
            onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
            isDisabled={currentPage === 1}
          >
            <LeftOutlineArrow
              fill={currentPage === 1 ? '#AAAAAA' : '#222222'}
            />
          </Button>

          {[...Array(5)].map((_, index) => {
            const pageNumber = getCurrentPageSet() + index;
            return pageNumber <= totalPages ? (
              <Button
                key={pageNumber}
                size="sm"
                variant={pageNumber === currentPage ? 'solid' : 'outline'}
                onClick={() => setCurrentPage(pageNumber)}
                color={pageNumber === currentPage ? '#EF6506' : '#222222'}
                borderColor={pageNumber === currentPage ? '#EF6506' : '#E2E8F0'}
                borderWidth={'1px'}
                borderRadius={'6px'}
              >
                {pageNumber}
              </Button>
            ) : null;
          })}

          <Button
            size="sm"
            onClick={() =>
              setCurrentPage(Math.min(totalPages, currentPage + 1))
            }
            isDisabled={currentPage === totalPages}
          >
            <RightOutlinedArrow
              fill={currentPage === totalPages ? '#AAAAAA' : '#222222'}
            />
          </Button>
        </HStack>
      </Flex>
    </>
  );
}
