import React from 'react';
// Admin Imports
import MainDashboard from 'views/admin/default';
import DataTables from 'views/admin/dataTables';

// Auth Imports
import SignIn from 'views/auth/signIn';
import Performance from 'views/admin/performance/Performance';
import Setting from 'views/admin/settings';
import { useGate } from 'statsig-react';
import SignInWithInvite from 'views/auth/signIn/signin';

export const useRoutes = () => {
  const { value: homeSection } = useGate('home_section'); // useGate inside a custom hook

  const routes = [
    {
      name: homeSection ? 'Home' : '',
      layout: '/admin',
      path: '/default',
      icon: homeSection ? 'home' : '',
      component: homeSection ? <MainDashboard /> : '', // Uncomment when needed
    },
    {
      name: 'Performance',
      layout: '/admin',
      path: '/performance',
      icon: 'performance',
      component: <Performance />,
    },
    {
      name: 'Peoples',
      layout: '/admin',
      icon: 'peoples',
      path: '/peoples',
      component: <DataTables />,
    },
    {
      name: 'Sign In',
      layout: '/auth',
      path: '/sign-in',
      component: <SignIn />,
    },
    {
      name: 'Sign In',
      layout: '/auth',
      path: '/sign-in/:id',
      component: <SignInWithInvite />,
    },
    {
      name: 'Setting',
      layout: '/admin',
      path: '/setting',
      component: <Setting />,
    },
  ];

  return routes;
};
