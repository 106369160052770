import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Checkbox,
  Flex,
  Image,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react';
import MessageIcon from 'assets/icons/MessageIcon';
import UploadIcon from 'assets/icons/Upload';
import DesktopIcon from 'assets/icons/Desktop';
import axios from 'axios';
import { useGate } from 'statsig-react';
import { setUserFullName } from 'store/userSlice/user';
import { useDispatch } from 'react-redux';
import { setUserProfileImage } from 'store/userSlice/user';
import { useSelector } from 'react-redux';

const API_URL =
  process.env.REACT_APP_API_URL || 'https://api.qa.twella.app/api/v1';

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const MyAccount = () => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nickName, setNickName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailSwitch, setEmailSwitch] = useState(true);
  const [newPassword, setNewPassword] = useState('');
  const [desktopSwitch, setDesktopSwitch] = useState(true);
  const [currentUser] = useState(JSON.parse(localStorage.getItem('user')));
  const userProfileImage = useSelector((state) => state.user.profileImage);
  const toast = useToast();

  const inputFileRef = useRef(null);

  const debouncedPhoneNumber = useDebounce(phoneNumber, 1500);
  const debouncedFirstName = useDebounce(firstName, 1000);
  const debouncedLastName = useDebounce(lastName, 1000);
  const debouncedPassword = useDebounce(newPassword, 1500);

  const [isFirstNameChanged, setIsFirstNameChanged] = useState(false);
  const [isLastNameChanged, setIsLastNameChanged] = useState(false);
  const [isPhoneNumberChanged, setIsPhoneNumberChanged] = useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [profileImageData, setProfileImageData] = useState('');
  const { value: profileNotifications } = useGate('profile_notifications');

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    setIsFirstNameChanged(true);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    setIsLastNameChanged(true);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    setIsPhoneNumberChanged(true);
  };

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
    setIsPasswordChanged(true);
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\+\d{1,3}\s\d{10,15}$/;
    return phoneRegex.test(phone);
  };

  const currentUserUpdate = useCallback(async (body = {}, file = null) => {
    try {
      const token = localStorage.getItem('token');
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      let formData;
      if (file) {
        formData = new FormData();
        formData.append('file', file);
        for (let key in body) {
          formData.append(key, body[key]);
        }
        headers['Content-Type'] = 'multipart/form-data';
      }

      const response = await axios.patch(
        `${API_URL}/dashboard/user/profile/`,
        body,
        { headers },
      );

      if (response?.data?.success) {
        const fullName = response?.data?.data?.fullName;
        setFirstName(fullName?.split(' ')[0] ? fullName?.split(' ')[0] : '');
        setLastName(fullName?.split(' ')[1] ? fullName?.split(' ')[1] : '');
        setEmail(response?.data?.data?.email);
        setPhoneNumber(response?.data?.data?.phone);
        setProfileImageData(response?.data?.data?.profilePic);
        return response?.data?.data;
      } else {
        console.log('NETWORK ISSUE');
      }
    } catch (error) {
      console.log(`Error -> ${error?.message}`);
    }
  }, []);

  const handleClick = () => {
    inputFileRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);

      try {
        const formData = new FormData();
        formData.append('file', file);

        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        };

        const response = await axios.post(
          `${API_URL}/dashboard/user/profile/updateProfilePhoto`,
          formData,
          { headers },
        );

        if (response?.data && response?.data?.success) {
          currentUserUpdate()
            .then((data) => {
              dispatch(setUserProfileImage(data?.profilePic));
              setProfileImageData(data?.profilePic);
            })
            .catch((error) => {
              console.log('Some thing went wrong');
            });
          toast({
            title: 'Success',
            description: 'Profile photo updated successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          console.log('Profile photo updated successfully');
        } else {
          throw new Error(
            response.data.message || 'Failed to update profile photo',
          );
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        toast({
          title: 'Error',
          description:
            error.response?.data?.message ||
            error.message ||
            'An error occurred while updating the profile photo',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  // The currentUserUpdate function remains the same as in the previous example

  // Fetch initial user data
  useEffect(() => {
    currentUserUpdate();
  }, [currentUserUpdate]);

  useEffect(() => {
    if (isFirstNameChanged || isLastNameChanged) {
      const fullName = `${debouncedFirstName} ${debouncedLastName}`.trim();

      const updateUserData = async () => {
        try {
          const body = { fullName };
          await currentUserUpdate(body);
          console.log('Success: User name updated');
          dispatch(setUserFullName(fullName));
          setIsFirstNameChanged(false);
          setIsLastNameChanged(false);
        } catch (error) {
          console.error(`Error: ${error.message}`);
        }
      };

      if (fullName.length > 0) {
        updateUserData();
      } else {
        console.log('Invalid full name');
      }
    }
  }, [currentUserUpdate, dispatch, debouncedFirstName, debouncedLastName]);

  useEffect(() => {
    if (isPhoneNumberChanged) {
      const updateUserData = async () => {
        try {
          const cleanedPhoneNumber = debouncedPhoneNumber.replace(/\s/g, '');
          const body = { phone: cleanedPhoneNumber };
          await currentUserUpdate(body);
          console.log('Success: Phone number updated');
          setIsPhoneNumberChanged(false);
        } catch (error) {
          console.error(`Error: ${error.message}`);
        }
      };

      if (validatePhoneNumber(debouncedPhoneNumber)) {
        updateUserData();
        toast({
          title: 'Phone number is update successfully',
          description: 'Phone number has updated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        console.log('Invalid phone number:', debouncedPhoneNumber);
        toast({
          title: 'Phone Number',
          description: 'Phone number should be in this format: +1 5555550110.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  }, [currentUserUpdate, debouncedPhoneNumber]);

  useEffect(() => {
    if (isPasswordChanged) {
      const updateUserData = async () => {
        try {
          const body = { password: debouncedPassword };
          await currentUserUpdate(body);
          console.log('Success: Password updated');
          setIsPasswordChanged(false);
        } catch (error) {
          console.error(`Error: ${error.message}`);
        }
      };

      if (debouncedPassword.length >= 8) {
        updateUserData();
        toast({
          title: 'Password',
          description: 'Password has updated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        console.log('Invalid password:', debouncedPassword);
        toast({
          title: 'Password',
          description: 'Password length should be minimum 8 characters.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  }, [currentUserUpdate, debouncedPassword]);

  return (
    <Box width={'65%'} margin={'24px auto 48px'}>
      {/* Heading start from here */}
      <Box>
        <Text fontSize={'20px'} fontWeight={'700'} lineHeight={'24px'}>
          Profile information
        </Text>
      </Box>

      {/* Profile Photos start from here */}
      <Flex
        justifyContent={'flex-start'}
        alignItems={'center'}
        margin={'16px 0 32px'}
      >
        <Box mr={'3%'}>
          {/* <Image src={ProfilePhote} /> */}
          <Avatar
            src={profileImageData ? profileImageData : userProfileImage}
            // height={'32px'}
            // width={'32px'}
            boxSize="160px"
            borderRadius="full"
            // mr="3"
          />
        </Box>
        <Flex
          flexDir={'column'}
          justify={'flex-start'}
          alignItems={'flex-start'}
          flexBasis={'40%'}
        >
          <Box>
            <Text fontSize={'16px'} fontWeight={'700'} lineHeight={'22px'}>
              Profile photo
            </Text>
          </Box>
          <Box margin={'16px 0'}>
            <Text
              fontSize={'14px'}
              fontWeight={'400'}
              lineHeight={'20px'}
              color={'#676666'}
            >
              Upload your image here. Images should not be bigger than 600x600
              pixels and must be smaller than 20Mb.
            </Text>
          </Box>
          <Flex
            justify={'center'}
            alignItems={'center'}
            border={'1px solid #E7E7E7'}
            borderRadius={'8px'}
            padding={'4px 8px'}
            cursor={'pointer'}
            onClick={handleClick}
          >
            <UploadIcon />
            <Text
              marginLeft={'8px'}
              fontSize={'14px'}
              fontWeight={'700'}
              lineHeight={'20px'}
            >
              Upload photo
            </Text>
            <input
              type="file"
              accept="image/*"
              ref={inputFileRef}
              style={{ display: 'none' }}
              onChange={(e) => handleFileChange(e)}
            />
          </Flex>
        </Flex>
      </Flex>

      {/* PROFILE TEXT INFO */}
      <Flex
        mt={'32px'}
        flexDir={'column'}
        alignItems={'flex-start'}
        className="TextInformation"
      >
        <Flex
          width={'100%'}
          justifyContent={'flex-start'}
          alignItems={'center'}
        >
          <Box
            flexDir={'column'}
            alignItems={'flex-start'}
            marginRight={'5%'}
            flexBasis={'35%'}
          >
            <Text
              fontSize={'14px'}
              fontWeight={'700'}
              lineHeight={'20px'}
              textTransform={'capitalize'}
            >
              name
            </Text>
            <Text
              fontSize={'14px'}
              fontWeight={'400'}
              lineHeight={'20px'}
              color={'#676666'}
            >
              Type your full name and surname here
            </Text>
          </Box>

          <Flex flexBasis={'60%'} width={'100%'}>
            <input
              name="firstName"
              id="firstName"
              autoComplete="off"
              value={firstName}
              onChange={handleFirstNameChange}
              className="TextInput"
              placeholder="First Name"
            />
            <input
              name="lastName"
              id="lastName"
              autoComplete="off"
              value={lastName}
              onChange={handleLastNameChange}
              className="TextInput"
              placeholder="Last Name"
            />
          </Flex>
        </Flex>

        {/* <Flex
          width={'100%'}
          justifyContent={'flex-start'}
          alignItems={'center'}
        >
          <Box
            flexDir={'column'}
            alignItems={'flex-start'}
            marginRight={'5%'}
            flexBasis={'35%'}
          >
            <Text
              fontSize={'14px'}
              fontWeight={'700'}
              lineHeight={'20px'}
              textTransform={'capitalize'}
            >
              nickname
            </Text>
            <Text
              fontSize={'14px'}
              fontWeight={'400'}
              lineHeight={'20px'}
              color={'#676666'}
            >
              Choose a short name
            </Text>
          </Box>

          <Flex flexBasis={'60%'} width={'100%'}>
            <input
              name="nickName"
              autoComplete="off"
              id="nickName"
              value={nickName}
              onChange={(e) => setNickName(e.target.value)}
              className="TextInput"
              placeholder="Nick Name"
            />
          </Flex>
        </Flex> */}

        <Flex
          width={'100%'}
          justifyContent={'flex-start'}
          alignItems={'center'}
        >
          <Box
            flexDir={'column'}
            alignItems={'flex-start'}
            marginRight={'5%'}
            flexBasis={'35%'}
          >
            <Text
              fontSize={'14px'}
              fontWeight={'700'}
              lineHeight={'20px'}
              textTransform={'capitalize'}
            >
              email
            </Text>
            <Text
              fontSize={'14px'}
              fontWeight={'400'}
              lineHeight={'20px'}
              color={'#676666'}
            >
              Type work email here
            </Text>
          </Box>

          <Flex flexBasis={'60%'} width={'100%'}>
            <input
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="TextInput"
              placeholder="Email"
              autoComplete="off"
              disabled
              readOnly={!!email}
            />
          </Flex>
        </Flex>

        <Flex
          width={'100%'}
          justifyContent={'flex-start'}
          alignItems={'center'}
        >
          <Box
            flexDir={'column'}
            alignItems={'flex-start'}
            marginRight={'5%'}
            flexBasis={'35%'}
          >
            <Text
              fontSize={'14px'}
              fontWeight={'700'}
              lineHeight={'20px'}
              textTransform={'capitalize'}
            >
              phone number
            </Text>
            {/* <Text>Type your full name and surname here</Text> */}
          </Box>

          <Flex flexBasis={'60%'} width={'100%'}>
            <input
              name="phoneNumber"
              id="phoneNumber"
              autoComplete="off"
              disabled
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              className="TextInput"
              placeholder="+1 555-555-0112"
            />
          </Flex>
        </Flex>

        <Flex
          width={'100%'}
          justifyContent={'flex-start'}
          alignItems={'center'}
        >
          <Box
            flexDir={'column'}
            alignItems={'flex-start'}
            marginRight={'5%'}
            flexBasis={'35%'}
          >
            <Text
              fontSize={'14px'}
              fontWeight={'700'}
              lineHeight={'20px'}
              textTransform={'capitalize'}
            >
              Change password
            </Text>
            {/* <Text>Type your full name and surname here</Text> */}
          </Box>

          <Flex flexBasis={'60%'} width={'100%'}>
            <input
              name="password"
              id="password"
              autoComplete="off"
              value={newPassword}
              onChange={handlePasswordChange}
              className="TextInput"
              placeholder="Password"
              type={'password'}
            />
          </Flex>
        </Flex>
      </Flex>

      {/* NOTIFICATIONS START FROM HERE */}
      {profileNotifications && currentUser?.role === 'super_admin' ? (
        <Box mt={'32px'}>
          <Box>
            <Text fontSize={'20px'} fontWeight={'700'} lineHeight={'24px'}>
              Notifications
            </Text>
          </Box>

          <Flex
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            margin={'16px 0 32px'}
          >
            <Box flexBasis={'2%'} marginRight={'3%'}>
              <MessageIcon width={'24'} height={'24'} />
            </Box>

            <Box flexBasis={'95%'}>
              <Flex justifyContent={'space-between'} alignItems={'center'}>
                <Box>
                  <Text
                    fontSize={'14px'}
                    fontWeight={'700'}
                    lineHeight={'20px'}
                    textTransform={'capitalize'}
                  >
                    email
                  </Text>
                  <Text
                    fontSize={'14px'}
                    fontWeight={'400'}
                    lineHeight={'20px'}
                    color={'#676666'}
                  >
                    Receive an email digest for unread notifications
                  </Text>
                </Box>
                <Box>
                  <Switch
                    isChecked={emailSwitch}
                    onChange={() => setEmailSwitch(!emailSwitch)}
                    mr="2"
                    sx={{
                      '& .chakra-switch__track': {
                        backgroundColor: '#C2C2C2',
                      },
                      '& .chakra-switch__track[data-checked]': {
                        backgroundColor: '#EF6506',
                      },
                    }}
                  />
                </Box>
              </Flex>

              <Flex
                padding={'16px'}
                mt={'8px'}
                flexDir={'column'}
                border={'1px solid #E7E7E7'}
                borderRadius={'12px'}
                className="Notifications"
                _disabled={emailSwitch}
              >
                <Flex
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  padding={'4px 0'}
                >
                  <Text>some notifications</Text>
                  <Checkbox
                    isDisabled={!emailSwitch}
                    colorScheme="red"
                    outline={'none'}
                    border={
                      emailSwitch ? '1px solid #E7E7E7' : '1px solid #E7E7E7'
                    }
                    bg={emailSwitch ? 'transparent' : '#E7E7E7'}
                    borderRadius={3}
                  />
                </Flex>

                <Flex
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  padding={'4px 0'}
                >
                  <Text>some notifications</Text>
                  <Checkbox
                    isDisabled={!emailSwitch}
                    colorScheme="red"
                    border={
                      emailSwitch ? '1px solid #E7E7E7' : '1px solid #E7E7E7'
                    }
                    bg={emailSwitch ? 'transparent' : '#E7E7E7'}
                    borderRadius={3}
                  />
                </Flex>

                <Flex
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  padding={'4px 0'}
                >
                  <Text>some notifications</Text>
                  <Checkbox
                    colorScheme="red"
                    isDisabled={!emailSwitch}
                    border={
                      emailSwitch ? '1px solid #E7E7E7' : '1px solid #E7E7E7'
                    }
                    bg={emailSwitch ? 'transparent' : '#E7E7E7'}
                    borderRadius={3}
                  />
                </Flex>

                <Flex
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  padding={'4px 0'}
                >
                  <Text>some notifications</Text>
                  <Checkbox
                    isDisabled={!emailSwitch}
                    colorScheme="red"
                    border={
                      emailSwitch ? '1px solid #E7E7E7' : '1px solid #E7E7E7'
                    }
                    bg={emailSwitch ? 'transparent' : '#E7E7E7'}
                    borderRadius={3}
                  />
                </Flex>
              </Flex>
            </Box>
          </Flex>

          <Flex
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            mt={'32px'}
          >
            <Box flexBasis={'2%'} marginRight={'3%'}>
              <DesktopIcon />
            </Box>

            <Box flexBasis={'95%'}>
              <Flex justifyContent={'space-between'} alignItems={'center'}>
                <Box>
                  <Text
                    fontSize={'14px'}
                    fontWeight={'700'}
                    lineHeight={'20px'}
                    textTransform={'capitalize'}
                  >
                    desktop
                  </Text>
                  <Text
                    fontSize={'14px'}
                    fontWeight={'400'}
                    lineHeight={'20px'}
                    color={'#676666'}
                  >
                    Receive an desktop digest for unread notifications
                  </Text>
                </Box>
                <Box>
                  <Switch
                    isChecked={desktopSwitch}
                    onChange={() => setDesktopSwitch(!desktopSwitch)}
                    mr="2"
                    sx={{
                      '& .chakra-switch__track': {
                        backgroundColor: '#C2C2C2',
                      },
                      '& .chakra-switch__track[data-checked]': {
                        backgroundColor: '#EF6506',
                      },
                    }}
                  />
                </Box>
              </Flex>

              <Flex
                padding={'16px'}
                mt={'8px'}
                flexDir={'column'}
                border={'1px solid #E7E7E7'}
                borderRadius={'12px'}
                className="Notifications"
              >
                <Flex
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  padding={'4px 0'}
                >
                  <Text>some notifications</Text>
                  <Checkbox
                    isDisabled={!desktopSwitch}
                    colorScheme="red"
                    border={
                      desktopSwitch ? '1px solid #E7E7E7' : '1px solid #E7E7E7'
                    }
                    bg={desktopSwitch ? 'transparent' : '#E7E7E7'}
                    borderRadius={3}
                  />
                </Flex>

                <Flex
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  padding={'4px 0'}
                >
                  <Text>some notifications</Text>
                  <Checkbox
                    isDisabled={!desktopSwitch}
                    colorScheme="red"
                    border={
                      desktopSwitch ? '1px solid #E7E7E7' : '1px solid #E7E7E7'
                    }
                    bg={desktopSwitch ? 'transparent' : '#E7E7E7'}
                    borderRadius={3}
                  />
                </Flex>

                <Flex
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  padding={'4px 0'}
                >
                  <Text>some notifications</Text>
                  <Checkbox
                    isDisabled={!desktopSwitch}
                    colorScheme="red"
                    border={
                      desktopSwitch ? '1px solid #E7E7E7' : '1px solid #E7E7E7'
                    }
                    bg={desktopSwitch ? 'transparent' : '#E7E7E7'}
                    borderRadius={3}
                  />
                </Flex>

                <Flex
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  padding={'4px 0'}
                >
                  <Text>some notifications</Text>
                  <Checkbox
                    isDisabled={!desktopSwitch}
                    colorScheme="red"
                    border={
                      desktopSwitch ? '1px solid #E7E7E7' : '1px solid #E7E7E7'
                    }
                    bg={desktopSwitch ? 'transparent' : '#E7E7E7'}
                    borderRadius={3}
                  />
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
};

export default MyAccount;
