import React from 'react';

function LeftArrow({ fill = '#222222', ...prop }) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M17.5 12H7.5M7.5 12L11.5 16M7.5 12L11.5 8"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LeftArrow;
