import React from 'react';

function LeftOutlineArrow({ fill = '#AAAAAA', ...prop }) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M9.8987 2.98479V1.92796C9.8987 1.83635 9.79343 1.78577 9.72233 1.84182L3.55905 6.65569C3.50669 6.69641 3.46431 6.74856 3.43517 6.80815C3.40602 6.86774 3.39087 6.9332 3.39087 6.99954C3.39087 7.06587 3.40602 7.13133 3.43517 7.19093C3.46431 7.25052 3.50669 7.30266 3.55905 7.34338L9.72233 12.1573C9.7948 12.2133 9.8987 12.1627 9.8987 12.0711V11.0143C9.8987 10.9473 9.86726 10.883 9.8153 10.842L4.89343 7.00022L9.8153 3.15706C9.86726 3.11604 9.8987 3.05178 9.8987 2.98479Z"
        fill={fill}
      />
    </svg>
  );
}

export default LeftOutlineArrow;
