import { Box, Flex, Switch, Text } from '@chakra-ui/react'
import PlusIcon from 'assets/icons/PlusIcon';
import SettingIcon from 'assets/icons/settingicon';
import React, { useState } from 'react'

const Access = () => {

  const [allowSendInvites, setAllowedInvites] = useState(true);
  const [thirdPartyApplications, setThirdPartyApplications] = useState(true);
  const [googleSignIn, setGoogleSignIn] = useState(true);

  return (
    <Box width={'65%'} margin={'24px auto 48px'}>
      {/* Security */}
      <Box>
        <Box>
          <Text
            textTransform={'capitalize'}
            fontSize={'20px'}
            fontWeight={'700'}
            lineHeight={'24px'}
          >
            security
          </Text>
        </Box>

        <Flex
          flexDir={'column'}
          paddingBottom={'16px'}
          borderBottom={'1px solid #E7E7E7'}
          margin={'16px 0'}
        >
          <Text
            fontSize={'14px'}
            lineHeight={'20px'}
            fontWeight={'700'}
            color={'#222222'}
          >
            Allow email domains
          </Text>

          <Flex justifyContent={'space-between'} alignItems={'flex-start'}>
            <Box flexBasis={'55%'}>
              <Text
                fontSize={'14px'}
                lineHeight={'20px'}
                fontWeight={'400'}
                color={'#757575'}
                pt={'4px'}
              >
                Anyone with an email address from these domains is allowed to
                sign up for with workspace.
              </Text>
            </Box>

            <Flex
              justifyContent={'space-between'}
              alignItems={'center'}
              borderRadius={'8px'}
              border={'1px solid #E7E7E7'}
              padding={'4px 8px'}
              cursor={'pointer'}
            >
              <PlusIcon />
              <Text
                color={'#222222'}
                fontSize={'14px'}
                lineHeight={'20px'}
                fontWeight={'700'}
              >
                Add domain
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          flexDir={'column'}
          paddingBottom={'16px'}
          borderBottom={'1px solid #E7E7E7'}
        >
          <Text
            fontSize={'14px'}
            lineHeight={'20px'}
            fontWeight={'700'}
            color={'#222222'}
          >
            Allow to send invites
          </Text>

          <Flex justifyContent={'space-between'} alignItems={'flex-start'}>
            <Box>
              <Text
                fontSize={'14px'}
                lineHeight={'20px'}
                fontWeight={'400'}
                color={'#757575'}
                pt={'4px'}
              >
                Allow members and admins to invites new users.
              </Text>
            </Box>

            <Box justifyContent={'space-between'} alignItems={'center'}>
              <Switch
                isChecked={allowSendInvites}
                onChange={() => setAllowedInvites(!allowSendInvites)}
                sx={{
                  '& .chakra-switch__track': {
                    backgroundColor: '#C2C2C2',
                  },
                  '& .chakra-switch__track[data-checked]': {
                    backgroundColor: '#EF6506',
                  },
                }}
              />
            </Box>
          </Flex>
        </Flex>
      </Box>

      {/* Applications
      <Box mt={'40px'}>
        <Box>
          <Text
            textTransform={'capitalize'}
            fontSize={'20px'}
            fontWeight={'700'}
            lineHeight={'24px'}
          >
            applications
          </Text>
        </Box>

        <Flex
          flexDir={'column'}
          paddingBottom={'16px'}
          borderBottom={'1px solid #E7E7E7'}
          margin={'16px 0'}
        >
          <Text
            fontSize={'14px'}
            lineHeight={'20px'}
            fontWeight={'700'}
            color={'#222222'}
          >
            Review third-party applications
          </Text>

          <Flex justifyContent={'space-between'} alignItems={'flex-start'}>
            <Box>
              <Text
                fontSize={'14px'}
                lineHeight={'20px'}
                fontWeight={'400'}
                color={'#757575'}
                pt={'4px'}
              >
                Manage the applications that users can install in this
                workspace.
              </Text>
            </Box>

            <Box>
              <Switch
                isChecked={thirdPartyApplications}
                onChange={() =>
                  setThirdPartyApplications(!thirdPartyApplications)
                }
                sx={{
                  '& .chakra-switch__track': {
                    backgroundColor: '#C2C2C2',
                  },
                  '& .chakra-switch__track[data-checked]': {
                    backgroundColor: '#EF6506',
                  },
                }}
              />
            </Box>
          </Flex>
        </Flex>
      </Box> */}

      {/* Authentications */}

      <Box mt={'40px'}>
        <Box>
          <Text
            textTransform={'capitalize'}
            fontSize={'20px'}
            fontWeight={'700'}
            lineHeight={'24px'}
          >
            authentications
          </Text>
        </Box>

        <Flex
          flexDir={'column'}
          paddingBottom={'16px'}
          borderBottom={'1px solid #E7E7E7'}
          margin={'16px 0'}
        >
          <Text
            fontSize={'14px'}
            lineHeight={'20px'}
            fontWeight={'700'}
            color={'#222222'}
            textTransform={'uppercase'}
          >
            saml
          </Text>

          <Flex justifyContent={'space-between'} alignItems={'flex-start'}>
            <Box>
              <Text
                fontSize={'14px'}
                lineHeight={'20px'}
                fontWeight={'400'}
                color={'#757575'}
                pt={'4px'}
              >
                Enable logins through your SAML identity provider using single
                sign-on functionality.
              </Text>
            </Box>

            <Flex
              justifyContent={'space-between'}
              alignItems={'center'}
              bg={'#EF6506'}
              borderRadius={'8px'}
              padding={'4px 8px'}
            >
              <SettingIcon fill={'#FFFDFB'} />
              <Text
                marginLeft={'4px'}
                color={'#FFFDFB'}
                textTransform={'capitalize'}
                fontSize={'14px'}
                lineHeight={'20px'}
                fontWeight={'700'}
              >
                configure
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          flexDir={'column'}
          paddingBottom={'16px'}
          borderBottom={'1px solid #E7E7E7'}
        >
          <Text
            fontSize={'14px'}
            lineHeight={'20px'}
            fontWeight={'700'}
            color={'#222222'}
          >
            Google
          </Text>

          <Flex justifyContent={'space-between'} alignItems={'flex-start'}>
            <Box>
              <Text
                fontSize={'14px'}
                lineHeight={'20px'}
                fontWeight={'400'}
                color={'#757575'}
                pt={'4px'}
              >
                Allow sign in through Google sign-on functionality
              </Text>
            </Box>

            <Box justifyContent={'space-between'} alignItems={'center'}>
              <Switch
                isChecked={googleSignIn}
                onChange={() => setGoogleSignIn(!googleSignIn)}
                sx={{
                  '& .chakra-switch__track': {
                    backgroundColor: '#C2C2C2',
                  },
                  '& .chakra-switch__track[data-checked]': {
                    backgroundColor: '#EF6506',
                  },
                }}
              />
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}

export default Access;
