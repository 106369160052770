import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RTLLayout from './layouts/rtl';
import { StatsigProvider } from 'statsig-react';
import { ChakraProvider } from '@chakra-ui/react';
import initialTheme from './theme/theme';
import { useEffect, useState } from 'react';
import { ClerkProvider, useAuth } from '@clerk/clerk-react';
import SignIn from 'views/auth/signIn';
import { Provider } from 'react-redux';
import { store } from 'store/store';

// Import your publishable key
const PUBLISHABLE_KEY =
  process.env.REACT_APP_CLERK_PUBLISHABLE_KEY ||
  'pk_test_Y2hvaWNlLWNhaW1hbi04MC5jbGVyay5hY2NvdW50cy5kZXYk';

if (!PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key');
}

function ProtectedRoute({ children }) {
  const { isSignedIn, isLoaded } = useAuth();

  if (!isLoaded) {
    return <div></div>; // Or a proper loading component
  }

  // if (!isSignedIn) {
  //   return <SignIn />;
  // }

  return children;
}

export default function App() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);

  return (
    <Provider store={store}>
      <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
        <StatsigProvider
          sdkKey={'client-pkcwnlJRilIrnP3dQxX5VJeRrjWYoWHc5OK7nyG4s0N'}
          waitForInitialization={true}
          options={{
            environment: {
              tier: 'staging',
            },
          }}
        >
          <ChakraProvider theme={currentTheme}>
            <Routes>
              <Route path="auth/*" element={<AuthLayout />} />
              <Route
                path="admin/*"
                element={
                  <ProtectedRoute>
                    <AdminLayout
                      theme={currentTheme}
                      setTheme={setCurrentTheme}
                    />
                  </ProtectedRoute>
                }
              />
              {/* <Route
                path="rtl/*"
                element={
                  <ProtectedRoute>
                    <RTLLayout
                      theme={currentTheme}
                      setTheme={setCurrentTheme}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Navigate to="/admin" replace />
                  </ProtectedRoute>
                }
              /> */}
              <Route path="*" element={<SignIn />} />
            </Routes>
          </ChakraProvider>
        </StatsigProvider>
      </ClerkProvider>
    </Provider>
  );
}
