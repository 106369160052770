import React from 'react';

function ArrowDownIcon({ fill = '#EF6506', ...prop }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_152_2208)">
        <path
          d="M20 12L18.59 10.59L13 16.17V4H11V16.17L5.42 10.58L4 12L12 20L20 12Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_152_2208">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 -1 24 24)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ArrowDownIcon;
