import React from 'react';

const PlusIcon = ({ width = 16, height = 16, fill = 'none', ...prop }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fill}
  >
    <path
      d="M8 3.33398V12.6673"
      stroke="#222222"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.3335 8H12.6668"
      stroke="#222222"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PlusIcon;

