import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Box, Flex, Input, Text } from '@chakra-ui/react';

const CustomPhoneInput = ({ value, onChange }) => {
  const [phoneValue, setPhoneValue] = useState(value || '');
  const [countryCode, setCountryCode] = useState('+1');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleChange = (phone, country) => {
    setCountryCode(`+${country.dialCode}`);
    const phoneWithoutCode = phone.slice(country.dialCode.length);
    setPhoneValue(phoneWithoutCode);

    if (typeof onChange === 'function') {
      onChange(phoneWithoutCode, country);
    }
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setPhoneValue(newValue);

    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  };
  
   useEffect(() => {
     setPhoneNumber(`${countryCode}${phoneValue}`);
   }, [countryCode, phoneValue]);

  return (
    <Flex border="1px solid #E2E8F0" borderRadius="md">
      <Box position="relative" width="70px">
        <PhoneInput
          country={'us'}
          value={phoneValue}
          onChange={handleChange}
          inputStyle={{ display: 'none' }}
          buttonStyle={{
            backgroundColor: 'transparent',
            border: 'none',
            borderRight: '1px solid #E2E8F0',
            borderRadius: '4px 0 0 4px',
            padding: '10px 5px 10px 5px',
            width: '100%',
            height: '100%',
            top: '10px',
          }}
          dropdownStyle={{
            width: '250px',
            maxHeight: '300px',
            overflowY: 'auto',
            top: '30px',
            left: '0px',
          }}
          containerStyle={{
            border: 'none',
          }}
        />
        <Text
          position="absolute"
          top="50%"
          left="45px"
          transform="translateY(-50%)"
          fontSize="14px"
          fontWeight="500"
          color="#333"
        >
          {countryCode}
        </Text>
      </Box>
      <Input
        flex={1}
        type="tel"
        value={phoneValue}
        onChange={handleInputChange}
        border="none"
        _focus={{ boxShadow: 'none' }}
        placeholder="123-456-7890"
      />
    </Flex>
  );
};

export default CustomPhoneInput;
