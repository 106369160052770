import React, { useState } from 'react';
import { Box, Text, Flex, ChakraProvider, extendTheme } from '@chakra-ui/react';
import CustomSelect from 'components/peoplePage/CustomSelect';
import LineBarChart from './LineBarChart';
import Switch from 'components/Switch';
import Chip from 'components/Chip';

const theme = extendTheme({
  fonts: {
    body: 'Inter, sans-serif',
    heading: 'Inter, sans-serif',
  },
});

const data = [
  { xAxis: 10, value1: 'Low', value2: 'High' },
  { xAxis: 20, value1: 'High', value2: 'Low' },
  { xAxis: 30, value1: 'Low', value2: 'Medium' },
  { xAxis: 40, value1: 'Medium', value2: 'High' },
  { xAxis: 50, value1: 'Medium', value2: 'Low' },
  { xAxis: 60, value1: 'High', value2: 'Medium' },
  { xAxis: 70, value1: 'Low', value2: 'Medium' },
  { xAxis: 80, value1: 'Low', value2: 'High' },
  { xAxis: 90, value1: 'Medium', value2: 'Low' },
  { xAxis: 100, value1: 'Low', value2: 'Medium' },
];

const chipsData = [
  { label: 'Isabella Lee', variant: 'primary' },
  { label: 'InfoMate', variant: 'tertiary' },
];

const ThinkingBarGraph = ({ data, period, onPeriodChange, periodOptions , barLables }) => {
  // const [period, setPeriod] = useState('6 Months');
  const [selectedOption, setSelectedOption] = useState('Average');

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  return (
    <ChakraProvider theme={theme}>
      <Box
        bg="transparent"
        borderRadius="12px"
        overflow="hidden"
        border="1px solid #E7E7E7"
        paddingBottom={'3%'}
        minH={'328px'}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          p={4}
          pb={4}
          borderBottom={'1px solid #E7E7E7'}
        >
          <Box>
            <Text fontSize="18px" fontWeight="600" color="#101828">
              Thinking
            </Text>
            <Text fontSize="14px" color="#475467" mt={1}>
              Description....
            </Text>
          </Box>
          <CustomSelect
            options={periodOptions}
            placeholder="6 months"
            width="150px"
            value={period}
            onChange={onPeriodChange}
          />
        </Flex>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={4}
          mt={'8px'}
          mb={'8px'}
        >
          <Flex alignItems="baseline">
            <Switch
              options={['Average', 'Median']}
              value={selectedOption}
              onChange={handleOptionChange}
            />
          </Flex>
          <Flex justifyContent="flex-start" px={4} mt={4} gap={'8px'}>
            {chipsData.map((chip, index) => (
              <Chip
                key={index}
                variant={chip.variant}
                label={chip.label}
                hasAvatar
              />
            ))}
          </Flex>
        </Flex>
        <LineBarChart
          data={data}
          isBarBackground={false}
          BarContainerHeight={'130px'}
          yAxisComponentLabel={'Reliability'}
          barLables={barLables}
        />
      </Box>
    </ChakraProvider>
  );
};

export default ThinkingBarGraph;
