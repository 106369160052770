import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  IconButton,
  Popover,
  PopoverTrigger,
  SimpleGrid,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import CheckTable from 'views/admin/dataTables/components/CheckTable';
import { columnsDataCheck } from 'views/admin/dataTables/variables/columnsData';
import MiniStatistics from 'components/card/MiniStatistics';
import ShareAndroidIcon from 'assets/icons/shareAndroidIcon';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import SettingIcon from 'assets/icons/settingicon';
import FilterListFilledIcon from 'assets/icons/FilterListFilledIcon';
import CustomizeTablePopup from 'components/peoplePage/CustomisedTablePopUp';
import CustomSelect from 'components/peoplePage/CustomSelect';
import axios from 'axios';
import PersonalInfo from 'components/personalInfo/PersonalInfo';
import ShareInviteModal from './InviteModal/InviteModal';

const API_URL =
  process.env.REACT_APP_API_URL || 'https://api.qa.twella.app/api/v1';

export default function Settings() {
  const [isCustomizeTableOpen, setIsCustomizeTableOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [visibleColumns, setVisibleColumns] = React.useState({
    name: true,
    email: true,
    id: true,
    location: true,
    lastSessions: true,
    totalSessions: true,
    status: true,
    subscriber: true,
    subscription: true,
    age: true,
    gender: true,
    race: true,
    politicalPreferences: true,
  });
  const [usersData, setUsersData] = useState([]);
  const [statisticsData, setStatisticsData] = useState({});
  const [showProfile, setShowProfile] = useState(false);
  const [singleUserData, setSingleUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [periods, setPeriods] = useState({
    peoplesPage: '6months',
    postNumber: 'month',
    activityTime: 'month',
    timeSpendOnCheckIn: 'month',
    awarenessDistribution: 'month',
    timeSpendOnCloserLook: 'month',
    evaluationSkills: 'month',
    thinking: 'month',
    categories: 'month',
  });
  const [userStatus, setUserStatus] = useState('All Users');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentUser] = useState(JSON.parse(localStorage.getItem('user')));
  const periodOptions = [
    { value: 'all', display: 'All' },
    { value: 'week', display: 'Last Week' },
    { value: 'month', display: 'Last Month' },
    { value: '3months', display: 'Last 3 Months' },
    { value: '6months', display: 'Last 6 Months' },
    { value: 'year', display: 'Last Year' },
  ];
  const usersOptions = [
    { value: 'all', display: 'All Users' },
    { value: 'Active', display: 'Active' },
    { value: 'Trial', display: 'Trial' },
    { value: 'Free', display: 'Free' },
  ];

  const getDisplayValue = () => {
    const selectedOption = periodOptions.find(
      (option) => option.value === periods.peoplesPage,
    );
    return selectedOption && selectedOption.display;
  };

  const statistics = [
    {
      name: 'Total users',
      value: statisticsData.totalUsers,
      change: '',
      text: '',
    },
    {
      name: 'New users',
      value: statisticsData?.newUsers?.count ? statisticsData?.newUsers?.count : "",
      syntax: statisticsData?.newUsers?.growth ? `${statisticsData?.newUsers?.growth}` : "",
      text: statisticsData?.newUsers?.percentage ? `${statisticsData?.newUsers?.percentage.toFixed(0)}% ${
        statisticsData?.newUsers?.growth
      } than last ${getDisplayValue()}` : "",
      syntaxFill: statisticsData?.newUsers?.growth ? `${
        statisticsData?.newUsers?.growth === 'more' ? '#25A259' : '#E02B00'
      }` : "",
    },
    {
      name: 'Total sessions',
     value: statisticsData?.totalSessions?.count ? statisticsData?.totalSessions?.count : "",
      syntax: statisticsData?.totalSessions?.growth ? `${statisticsData?.totalSessions?.growth}` : "",
      text: statisticsData?.totalSessions?.percentage ? `${statisticsData?.totalSessions?.percentage.toFixed(0)}% ${
        statisticsData?.totalSessions?.growth
      } than last ${getDisplayValue()}` : "",
      syntaxFill: statisticsData?.totalSessions?.growth ? `${
        statisticsData?.totalSessions?.growth === 'more' ? '#25A259' : '#E02B00'
      }` : "",
    }
  ];

  const handleSendInvite = (data) => {
    console.log('Sending invite:', data);
    setIsModalOpen(false);
  };

  const fetchData = useCallback(async (endpoint, period, dataKey) => {
    const token = localStorage.getItem('token');

    try {
      setIsLoading(true);

      let url = `${API_URL}/dashboard/users?skip=0&limit=10`;

      // Add period parameter only if it's not 'all'
      if (period !== 'all') {
        url += `&period=${period}`;
      }

      // Add institution parameter
      url += `&institution=${currentUser?.institution}`;

      console.log(url);

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const usersArray = Array.isArray(response.data.data.users)
        ? response.data.data.users
        : Object.values(response.data.data.users);
      setUsersData(usersArray);
      setFilteredUsers(usersArray);
      setStatisticsData(response.data.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const filterUsers = useCallback(() => {
    let filtered = usersData;
    if (userStatus !== 'All Users') {
      filtered = filtered.filter((user) => {
        switch (userStatus) {
          case 'Free':
            return user.subscriber === 'Free';
          case 'Active':
            return user.subscriber === 'Active';
          case 'Trial':
            return user.subscriber === 'Trial';
          default:
            return true;
        }
      });
    }

    if (searchTerm) {
      filtered = filtered.filter((user) =>
        (user.name || user.fullName || '')
          .toLowerCase()
          .includes(searchTerm.toLowerCase()),
      );
    }
    setFilteredUsers(filtered);
  }, [usersData, userStatus, searchTerm]);

  useEffect(() => {
    filterUsers();
  }, [filterUsers, usersData, userStatus, searchTerm]);

  useEffect(() => {
    const fetchAllData = async () => {
      await Promise.all([
        fetchData('dashboard/users/getPostsNumbers', periods?.peoplesPage),
      ]);
    };

    fetchAllData();
  }, [fetchData, periods]);

  const handleUserStatusChange = (newStatus) => {
    setUserStatus(newStatus);
  };

  const mapUserData = useCallback((users) => {
    if (!Array.isArray(users) || users.length === 0) {
      return [{ message: 'No records found' }];
    }

    return users.map((user) => ({
      name: user.name || user.fullName || '',
      email: user.email || '',
      phoneNumber: user.phone || '',
      id: user.id || '',
      userId: user._id,
      location: user.location
        ? `${user.location.city}, ${user.location.state}`
        : '',
      lastSession: user.updatedAt
        ? new Date(user.updatedAt).toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
          })
        : '',
      totalSessions: user.timeSpent ? Math.round(user.timeSpent / 60) : '',
      status: user.verificationStatus || '',
      subscriber: user.subscriber || '',
      subscription: '',
      age: user.birthDate ? calculateAge(user.birthDate) : '',
      gender: user.gender ? user.gender.name : '',
      race: user.racialIdentity ? user.racialIdentity.name : '',
      politicalPreferences: user.politicalPreference
        ? user.politicalPreference.name
        : '',
      institution: user?.institution || '',
    }));
  }, []);

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDateObj.getDate())
    ) {
      age--;
    }
    return age.toString();
  };

  const handlePeriodChange = (dataKey, newPeriod) => {
    setPeriods((prevPeriods) => ({
      ...prevPeriods,
      [dataKey]: newPeriod,
    }));
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '35px' }} position={'relative'}>
      {showProfile === false ? (
        <>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
          >
            <Text
              fontSize={'28px'}
              fontWeight={'bold'}
              color={'#222222'}
              lineHeight={'32px'}
            >
              People
            </Text>
            <Box
              width={'168px'}
              height={'36px'}
              borderRadius={'8px'}
              bg={'#EF6506'}
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              padding={'6px 8px 6px 8px'}
              gap={'8px'}
              cursor={'pointer'}
              onClick={onOpen}
            >
              <ShareAndroidIcon />
              <Text color="white">Invite new people</Text>
            </Box>
          </Box>
          <ShareInviteModal
            isOpen={isOpen}
            onClose={onClose}
            onSendInvite={handleSendInvite}
          />
          <SimpleGrid mt={'4'} spacing={{ base: '10px', xl: '10px' }}>
            <SimpleGrid
              width={'100%'}
              columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
              gap="8px"
              bg={'#FFFDFB'}
              justifyContent={'space-between'}
              className="miniStatisticsContainer"
            >
              {statistics?.map((stat, index) => (
                <MiniStatistics
                  key={index}
                  name={stat?.name}
                  syntax={stat?.syntax}
                  syntaxFill={stat?.syntaxFill}
                  text={stat?.text}
                  value={stat?.value?.count || stat?.value}
                  change={stat?.change}
                />
              ))}
            </SimpleGrid>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              mt={'12px'}
            >
              <Flex alignItems="center" gap="10px">
                <SearchBar
                  placeholder="Search by name"
                  borderRadius="8px"
                  width="200px"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <CustomSelect
                  options={usersOptions}
                  width="150px"
                  value={userStatus}
                  onChange={(newStatus) => handleUserStatusChange(newStatus)}
                  placeholder={'All users'}
                />
                <CustomSelect
                  options={periodOptions}
                  placeholder="6 months"
                  width="150px"
                  value={periods.peoplesPage}
                  onChange={(newPeriod) =>
                    handlePeriodChange('peoplesPage', newPeriod)
                  }
                />
              </Flex>
              <Flex alignItems="center" gap="10px" position="relative">
                <Box position="relative">
                  <Popover>
                    <PopoverTrigger>
                      <Button
                        border="1px solid #E7E7E7"
                        borderRadius="8px"
                        leftIcon={<SettingIcon />}
                        bg="none"
                        _hover={{ bg: '#FFF8F3' }}
                        _active={{ bg: '#FFF8F3' }}
                        onClick={() =>
                          setIsCustomizeTableOpen(!isCustomizeTableOpen)
                        }
                      >
                        Customize table
                      </Button>
                    </PopoverTrigger>
                    <CustomizeTablePopup
                      isOpen={isCustomizeTableOpen}
                      onClose={() =>
                        setIsCustomizeTableOpen(!isCustomizeTableOpen)
                      }
                      visibleColumns={visibleColumns}
                      setVisibleColumns={setVisibleColumns}
                      setSingleUserData={setSingleUserData}
                    />
                  </Popover>
                </Box>
                <IconButton
                  border="1px solid #E7E7E7"
                  borderRadius="8px"
                  aria-label="Filter"
                  icon={<FilterListFilledIcon />}
                  bg="none"
                  _hover={{ bg: '#FFF8F3' }}
                  _active={{ bg: '#FFF8F3' }}
                />
              </Flex>
            </Flex>
            <CheckTable
              columnsData={columnsDataCheck}
              visibleColumns={visibleColumns}
              tableData={mapUserData(filteredUsers)}
              setSingleUserData={setSingleUserData}
              setShowProfile={setShowProfile}
            />
          </SimpleGrid>
        </>
      ) : (
        <>
          {singleUserData && (
            <PersonalInfo
              singleUserData={singleUserData}
              setShowProfile={setShowProfile}
            />
          )}
        </>
      )}
    </Box>
  );
}
