import React from 'react';

function ShareAndroidIcon({ fill = '#EF6506', ...prop }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 14.667C13.1046 14.667 14 13.7716 14 12.667C14 11.5624 13.1046 10.667 12 10.667C10.8954 10.667 10 11.5624 10 12.667C10 13.7716 10.8954 14.667 12 14.667Z"
        stroke="#FFFDFB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 5.33301C13.1046 5.33301 14 4.43757 14 3.33301C14 2.22844 13.1046 1.33301 12 1.33301C10.8954 1.33301 10 2.22844 10 3.33301C10 4.43757 10.8954 5.33301 12 5.33301Z"
        stroke="#FFFDFB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 10C5.10457 10 6 9.1046 6 8C6 6.8954 5.10457 6 4 6C2.89543 6 2 6.8954 2 8C2 9.1046 2.89543 10 4 10Z"
        stroke="#FFFDFB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3333 4.33301L5.66663 6.99967"
        stroke="#FFFDFB"
        strokeWidth="1.5"
      />
      <path d="M5.66663 9L10.3333 11.6667" stroke="#FFFDFB" strokeWidth="1.5" />
    </svg>
  );
}

export default ShareAndroidIcon;
