// chakra imports
import { Box, Flex, Stack } from '@chakra-ui/react';
//   Custom components
import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';
import SidebarCard from 'components/sidebar/components/SidebarCard';
import React from 'react';

// FUNCTIONS

function SidebarContent(props) {
  const { routes } = props;
  // SIDEBAR
  return (
    <Flex
      direction="column"
      height="100%"
      pt="58px"
      borderRadius="30px"
      // borderRight={'1px solid right'}
    >
      {/* <Brand /> */}
      <Stack direction="column" mb="auto" mt="8px">
        <Box pe={{ md: '16px', '2xl': '1px' }}>
          <Links routes={routes} />
        </Box>
      </Stack>

      <Stack
        direction="column"
        mb={'10'}
        justifyContent={'center'}
        alignItems={'center'}
        maxWidth={'100px'}
      >
        <Box maxWidth={'58px'}>
          <SidebarCard />
        </Box>
      </Stack>
    </Flex>
  );
}

export default SidebarContent;
