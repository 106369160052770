import React from 'react';

function CustomiseIcon({ fill = '#222222', ...prop }) {
  return (
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none">
      <path
        d="M1.66675 2.66699C2.21903 2.66699 2.66675 2.21928 2.66675 1.66699C2.66675 1.11471 2.21903 0.666992 1.66675 0.666992C1.11446 0.666992 0.666748 1.11471 0.666748 1.66699C0.666748 2.21928 1.11446 2.66699 1.66675 2.66699ZM1.66675 7.00033C2.21903 7.00033 2.66675 6.55259 2.66675 6.00033C2.66675 5.44806 2.21903 5.00033 1.66675 5.00033C1.11446 5.00033 0.666748 5.44806 0.666748 6.00033C0.666748 6.55259 1.11446 7.00033 1.66675 7.00033ZM2.66675 10.3337C2.66675 10.8859 2.21903 11.3337 1.66675 11.3337C1.11446 11.3337 0.666748 10.8859 0.666748 10.3337C0.666748 9.78139 1.11446 9.33366 1.66675 9.33366C2.21903 9.33366 2.66675 9.78139 2.66675 10.3337ZM6.33341 2.66699C6.88568 2.66699 7.33341 2.21928 7.33341 1.66699C7.33341 1.11471 6.88568 0.666992 6.33341 0.666992C5.78115 0.666992 5.33341 1.11471 5.33341 1.66699C5.33341 2.21928 5.78115 2.66699 6.33341 2.66699ZM7.33341 6.00033C7.33341 6.55259 6.88568 7.00033 6.33341 7.00033C5.78115 7.00033 5.33341 6.55259 5.33341 6.00033C5.33341 5.44806 5.78115 5.00033 6.33341 5.00033C6.88568 5.00033 7.33341 5.44806 7.33341 6.00033ZM6.33341 11.3337C6.88568 11.3337 7.33341 10.8859 7.33341 10.3337C7.33341 9.78139 6.88568 9.33366 6.33341 9.33366C5.78115 9.33366 5.33341 9.78139 5.33341 10.3337C5.33341 10.8859 5.78115 11.3337 6.33341 11.3337Z"
        fill={fill}
      />
    </svg>
  );
}

export default CustomiseIcon;
