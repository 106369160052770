import axios from 'axios';

export const clearToken = () => {
  try {
    // Clear the Authorization header
    axios.defaults.headers.common['Authorization'] = '';

    // Remove the token from localStorage
    localStorage.removeItem('token');

    // Optionally, you can also clear other auth-related items
    localStorage.removeItem('user');

    console.log('Token cleared successfully');
  } catch (e) {
    console.error('[Clear Token Error]:', e);
  }
};

export const secondsToMinutesAndSeconds = (totalSeconds) => {
  if (isNaN(totalSeconds) || totalSeconds < 0) {
    return '00:00';
  }

  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.floor(totalSeconds % 60);

  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
};

export const secondsToMinutesAndSecondsObject = (totalSeconds) => {
  if (isNaN(totalSeconds) || totalSeconds < 0) {
    return { minutes: 0, seconds: 0 };
  }

  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.floor(totalSeconds % 60);

  return { minutes, seconds };
};

export const secondsToMinutes = (totalSeconds) => {
  if (isNaN(totalSeconds) || totalSeconds < 0) {
    return { minutes: 0 };
  }

  const minutes = Math.floor(totalSeconds / 60);

  return { minutes };
};

export const secondsToHours = (totalSeconds) => {
  if (isNaN(totalSeconds) || totalSeconds < 0) {
    return '00:00:00';
  }

  const hours = Math.floor(totalSeconds / 3600);

  const formattedHours = String(hours).padStart(2, '0');

  return `${formattedHours}`;
};

export const secondsToHoursObject = (totalSeconds) => {
  if (isNaN(totalSeconds) || totalSeconds < 0) {
    return '00:00:00';
  }

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};
