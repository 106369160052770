import { Box, Text } from '@chakra-ui/react';
import SettingIconBig from 'assets/icons/SettingIconBig';
import React from 'react';
import { Link } from 'react-router-dom';

export default function SidebarDocs() {
  return (
    <Link to={"/admin/setting"}>
      <Box display={'flex'} flexDir={'column'} alignItems={'center'}>
        <SettingIconBig />
        <Box>
          <Text
            textTransform={'capitalize'}
            fontSize={'12px'}
            fontWeight={'500'}
            lineHeight={'16px'}
            mt={'4px'}
          >
            settings
          </Text>
        </Box>
      </Box>
    </Link>
  );
}
