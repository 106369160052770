import React from 'react';
import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
  Box,
} from '@chakra-ui/react';
import Card from 'components/card/Card.js';
import ArrowDownIcon from 'assets/icons/arrowDownIcon';
import ArrowUpIcon from 'assets/icons/ArrowUpIcon';

export default function MiniStatistics(props) {
  const { name, value, change, syntax, text, syntaxFill } = props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('brand.500', 'white');
  const bgIcon = useColorModeValue('secondaryGray.300', 'navy.700');
  let statisticsColor = useColorModeValue('#FFFDFB', 'navy.800');

  return (
    <Card bg={statisticsColor} border={'1px solid #E7E7E7'}>
      <Flex flexDirection="row" align="center" justify="space-between" w="100%">
        <Stat me="auto">
          <StatLabel
            fontSize="14px"
            color="#676666"
            fontWeight="bold"
            pb=".1rem"
          >
            {name}
          </StatLabel>
          <Flex align="center">
            <Box
              borderRadius="50%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              color={iconColor}
              fontSize="24px"
              flexBasis={name.toLowerCase() === "total users" ? 0 : "8%"}
            >
              {name !== 'Total users' && (
                <>
                  {syntax === 'less' ? (
                    <ArrowDownIcon fill={syntaxFill} />
                  ) : (
                    <ArrowUpIcon fill={syntaxFill} />
                  )}
                </>
              )}
            </Box>
            <StatNumber
              fontSize="24"
              color={textColor}
              marginRight={'2'}
              marginLeft={'2'}
            >
              {value}
            </StatNumber>
            <Text fontSize={'14px'}>{text}</Text>
          </Flex>
        </Stat>
      </Flex>
    </Card>
  );
}
