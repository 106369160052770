import React from 'react';

function ChevronRight({ fill = '#EF6506', ...prop }) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M9.88712 7.52582L7.06045 4.69915C6.99847 4.63667 6.92474 4.58707 6.8435 4.55322C6.76226 4.51938 6.67512 4.50195 6.58712 4.50195C6.49911 4.50195 6.41197 4.51938 6.33073 4.55322C6.24949 4.58707 6.17576 4.63667 6.11378 4.69915C5.98962 4.82406 5.91992 4.99303 5.91992 5.16915C5.91992 5.34528 5.98962 5.51424 6.11378 5.63915L8.47378 7.99915L6.11378 10.3592C5.98962 10.4841 5.91992 10.653 5.91992 10.8292C5.91992 11.0053 5.98962 11.1742 6.11378 11.2992C6.17608 11.3609 6.24995 11.4098 6.33118 11.443C6.4124 11.4762 6.49938 11.493 6.58712 11.4925C6.67485 11.493 6.76183 11.4762 6.84305 11.443C6.92428 11.4098 6.99816 11.3609 7.06045 11.2992L9.88712 8.47249C9.9496 8.41051 9.9992 8.33678 10.033 8.25554C10.0669 8.1743 10.0843 8.08716 10.0843 7.99915C10.0843 7.91114 10.0669 7.82401 10.033 7.74277C9.9992 7.66153 9.9496 7.58779 9.88712 7.52582Z"
        fill={fill}
      />
    </svg>
  );
}

export default ChevronRight;
