// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

.css-1phbokr {
  padding: 0px !important;
  border-radius: 0 !important;
  box-shadow: none !important
}

li {
  list-style-type: none;
}

.css-1qw2h6f {
  padding: 10px !important;
}

.barChat.css-1jv5nha {
  background: transparent;
}

.miniStatisticsContainer.css-14qnnvo {
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  width: "100%" !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/App.css"],"names":[],"mappings":"AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,2BAA2B;EAC3B;AACF;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,2DAA2D;EAC3D,wBAAwB;AAC1B","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap\");\n\nbody {\n  font-family: \"DM Sans\", sans-serif;\n}\n\noption {\n  color: black;\n}\n\n.css-1phbokr {\n  padding: 0px !important;\n  border-radius: 0 !important;\n  box-shadow: none !important\n}\n\nli {\n  list-style-type: none;\n}\n\n.css-1qw2h6f {\n  padding: 10px !important;\n}\n\n.barChat.css-1jv5nha {\n  background: transparent;\n}\n\n.miniStatisticsContainer.css-14qnnvo {\n  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;\n  width: \"100%\" !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
